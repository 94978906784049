import React from "react";
import bannerImage from "../images/hero-banner-top.png";

const HeroBanner = () => {
  return (
    <section className=" bg-blue-gradient">
      <div className="flex flex-col md:flex-row justify-between items-center self-stretch w-full px-[15px] md:px-0 py-[30px] lg:py-[50px] container gap-6 md:gap-10">
        <div className="text-white font-poppins">
          <span className="font-bold text-lg md:text-xl leading-3 md:leading-8">
            Better together, Palinode is now a part of Provana!
          </span>
          {/* <p className="pt-3 md:pt-0 font-normal text-sm leading-5 md:text-base md:leading-7">
            Uniting strengths to fuel innovation and growth together.
          </p> */}
        </div>
        <div className="max-w-[430px]">
          <img
            src={bannerImage}
            alt="banner"
            className="max-w-[90%] md:max-w-[100%]"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
