import React from 'react';
import logo from "../images/logo.png";
import modalclose from "../images/modalclose.svg";

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    
    return (
        <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="bg-[#F4F7F9] max-sm:py-[20px] sm:p-[20px] rounded-none w-full h-full relative overflow-auto">
                <div className="h-full w-full">
                    <div className='container'>
                        <div>
                            <div className='flex justify-between'>
                                <img src={logo} alt="logo" className='max-md:max-w-[120px]'/>
                                <button onClick={onClose} className="w-[40px] h-[40px] flex justify-center items-center border border-solid rounded-[50%] border-[#DBE3EB] hover:bg-[#DBE3EB] transition-all duration-[0.4s]">
                                    <img src={modalclose} alt="close" />
                                </button>
                            </div>
                            <div className='max-md:mt-[25px] md:mt-[40px]'>
                                <span className='block text-center text-blue font-poppins max-md:text-[22px] md:text-[26px] font-semibold leading-normal capitalize'>Privacy Policy</span>
                            </div>
                            <div className='max-md:mt-[22px] md:mt-[30px] w-full max-w-full sm:max-w-[400px] md:max-w-[450px] lg:max-w-[600px] mx-auto rounded-[20px] bg-white max-sm:pt-[22px] sm:pt-[25px] md:pt-[30px] lg:pt-[40px] max-sm:px-[20px] sm:px-[25px] md:px-[30px] lg:px-[50px] max-sm:pb-[22px] sm:pb-[25px] md:pb-[30px] lg:pb-[50px]'>

                                <div className="text-sm text-[#45647BCC] space-y-4">
                                    <p>This Privacy Policy applies to the website www.palinode.io (also called the website). By using this website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree to be bound by the terms and conditions of this Privacy Policy, please do not access our website.</p>
                                    <p>This Privacy Policy describes what personal information we may collect from you, and what we do with that information. Personal information includes data elements such as your name, title, email address, phone number, and IP address.</p>
                                    <p>By visiting our website, you expressly consent to our use and disclosure of your personal information subject to the terms and conditions stated within this Privacy Policy.</p>

                                    <h3 className="font-bold">1) Why and How We Collect Information</h3>
                                    <p>Our primary purpose in collecting personal information is to provide you with information related to Palinode’s services. You can view the website without the need to enter personal information. However, if you do provide your personal information (e.g. by submitting a request for more information), you are no longer anonymous to us. Some entries are required, and some entries are optional, when completing an information request. You have the option to decline to provide us with required information; however, if this is the case, we may not be able to respond to your request.</p>
                                    <p>We reserve the right to require additional personal information in the future.</p>

                                    <h3 className="font-bold">Use of Cookies</h3>
                                    <p>We use data collection “cookies” on certain pages of both of our websites. Cookies are small files that we place on your computer. We do not relate the cookie information to any of your personal information. We use only “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session.</p>
                                    <p>Some browsers allow you to decline cookies; however, our website will not work correctly if you do so.</p>

                                    <h3 className="font-bold">2) How We Use Your Information</h3>
                                    <p>We use your personal information to provide you with information you request related to Palinode’s services. We may also review and compare your personal information against other users from your organization.</p>
                                    <p>We post client feedback and comments on our websites. We will obtain your written consent prior to posting your feedback and comments. If you wish to request the removal of your feedback or comments, please send a request to info@palinode.io.</p>

                                    <h3 className="font-bold">3) Our Disclosure of Your Information</h3>
                                    <p>We will not share, sell or rent any of your personal information to third parties other than as described in this Privacy Policy. Your personal information may be disclosed as follows:</p>
                                    <p>(a) Service providers facilitate some operational aspects of our website and your personal information may be available to these service providers. Our service providers are subject to confidentiality agreements with us and other legal restrictions that prohibit their use of your personal information for any other purpose except to provide Palinode with the services for which they have been contracted.</p>
                                    <p>(b) We cooperate with law enforcement inquiries, including those related to intellectual property rights and fraud. Therefore, in response to a verified request by law enforcement or other government officials relating to a criminal investigation or alleged illegal activity, with or without a subpoena, you authorize us to disclose your personal information and usage history.</p>
                                    <p>(c) Any client feedback or comments that you provide may be read, collected, and used by others who access our website. If you wish to request the removal of your feedback or comments, please send a request to info@palinode.io.</p>
                                    <p>Although we use industry standard security and privacy practices, we do not promise, and you should not expect, that your personal information or feedback and comments will always remain private.</p>

                                    <h3 className="font-bold">4) Unsolicited Communication</h3>
                                    <p>We do not tolerate spam. You are not licensed to send any message to anyone else (either by email or regular mail) that promotes Palinode without their express (preferably written) consent. If you receive spam that appears to come from Palinode, please contact us at info@palinode.io.</p>

                                    <h3 className="font-bold">5) Reviewing, Updating, and Deleting Your Personal Information</h3>
                                    <p>You have the ability to review, update, and delete the personal information you submit to Palinode. If you wish to update or delete your personal information, please contact us at info@palinode.io. We will only modify or delete your personal information if we are able to authenticate your identity to our full satisfaction.</p>

                                    <h3 className="font-bold">6) Retention of Your Personal Information</h3>
                                    <p>We reserve the right to retain and use some or all of your personal information as necessary to comply with any legal obligations. Therefore, you should not expect that we will remove all of your personal information in response to your requests. However, any personal information we retain will only be available to certain Palinode personnel or certain of our service providers.</p>

                                    <h3 className="font-bold">7) Collection of Information by Others</h3>
                                    <p>Except as otherwise expressly stated in this Privacy Policy, this Privacy Policy only addresses the use and disclosure of information we collect from you. If you choose to disclose your personal information to others using our system (including by following links to other websites), different terms and conditions may apply to their use or disclosure of the personal information you disclose to them. Since Palinode does not control the privacy policies of third parties, or the actions of other individuals, you are subject to the privacy policies of that third party or those individuals. We encourage you to make sure the recipients are authenticated to your full satisfaction before you disclose any of your personal information.</p>

                                    <h3 className="font-bold">8) Information Security Practices</h3>
                                    <p>We treat data as an asset that must be protected against loss and unauthorized access. Any personal information you choose to provide to us will be stored on Palinode’s servers which are located in a secure data center within the United States. Procedural and technical safeguards are used to protect your personal information against loss or theft, as well as unauthorized access and disclosure. These safeguards include encryption, the use of firewalls, and Secure Socket Layers. However, notwithstanding our best efforts, “perfect security” does not exist on the Internet and you should not have expectations of “perfect security” with our website. If you have any questions about security, you can contact us at info@palinode.io.</p>

                                    <h3 className="font-bold">9) Changes to this Policy</h3>
                                    <p>We reserve the right to modify this Privacy Policy at any time. If we make any changes to our Privacy Policy, we will notify you via email and/or by posting the changes on our website prior to the change becoming effective. We encourage you to review our Privacy Policy from time to time.</p>

                                    <h3 className="font-bold">10) Contact Us</h3>
                                    <p>If you have any questions regarding this Privacy Policy, you may contact us at info@palinode.io, by calling 1.866.821.4484, or via postal mail at 101 Westpark Dr, Suite 100, Brentwood, TN 37027.</p>

                                    <p><em>Last updated: 09/24/2024</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyModal;
